<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button
          label="طباعه جدول المباريات"
          icon="pi pi-plus"
          class="p-ml-2 p-button-success"
          @click="printHtml()"
        />
        <Button
          label="طباعه الاسكورشيت"
          icon="pi pi-print"
          @click="printHtmlPlayers()"
          :disabled="selectedItems.length == 0"
          class="p-button-info"
        />
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic p-ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help p-ml-2"
          v-tooltip="'تحميل'"
          @click="exportCSV($event)"
        />
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.go(-1)"
        />
        <!-- <Button icon="pi pi-search" class="p-ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers"
      :rows="100"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :loading="loading"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
      editMode="cell"
    >
      <template #header>
        <div class="table-header">
          قائمه جدول البطوله

          <div style="text-align:left">
            <!-- <MultiSelect
              :value="selectedColumns"
              :options="columns"
              optionLabel="header"
              @input="onToggle"
              placeholder="اختيار الاعمده"
              style="width: 20em"
            /> -->
          </div>
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <Column selectionMode="multiple" field="id" headerStyle="width: 3em" />
      <!-- <Column
        field="id"
        header="المسابقه"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          {{ slotProps.data.leaguesId.name }}
        </template>
      </Column> -->
      <Column field="matcheNumber" header="رقم المباره" :sortable="true" />

      <Column
        field="id"
        header="الفرقه الاولي"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <img
            :src="$baseUploadURL + slotProps.data.academieOneId.image"
            class="dt-image"
          />
          <p>{{ slotProps.data.academieOneId.userName }}</p>
          <b-form-spinbutton
            id="demo-sb"
            @change="updateStatus(slotProps.data)"
            v-model="slotProps.data.teamOneGoal"
            min="0"
            max="100"
          ></b-form-spinbutton>
        </template>
      </Column>

      <Column
        field="id"
        header="الفرقه الثانيه"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <img
            :src="$baseUploadURL + slotProps.data.academieTwoId.image"
            class="dt-image"
          />
          <p>{{ slotProps.data.academieTwoId.userName }}</p>
          <b-form-spinbutton
            id="demo-sb"
            v-model="slotProps.data.teamTwoGoal"
            @change="updateStatus(slotProps.data)"
            min="0"
            max="100"
          ></b-form-spinbutton>
        </template>
      </Column>

      <Column field="stadium" header="ملعب رقم" :sortable="true">
        <template #editor="slotProps">
          <InputText
            v-model="slotProps.data[slotProps.column.field]"
            @input="onCellEdit($event, slotProps)"
          />
        </template>
      </Column>

      <Column field="timeStart" header="واقت البداء" :sortable="true">
        <template #body="slotProps">
          {{ $TimeFormat(slotProps.data.timeStart) }}
        </template>
        <template #editor="slotProps">
          <Calendar
            id="time12"
            :timeOnly="true"
            hourFormat="12"
            v-model="slotProps.data[slotProps.column.field]"
            @input="onCellEdit($event, slotProps)"
          />
        </template>
      </Column>
      <Column field="timeEnd" header="واقت الانتهاء" :sortable="true">
        <template #body="slotProps">
          {{ $TimeFormat(slotProps.data.timeEnd) }}
        </template>
        <template #editor="slotProps">
          <Calendar
            id="time12"
            :timeOnly="true"
            hourFormat="12"
            v-model="slotProps.data[slotProps.column.field]"
            @input="onCellEdit($event, slotProps)"
          />
        </template>
      </Column>

      <!-- <Column field="id" bodyStyle="text-align: center; overflow: visible">
        <template #body="slotProps">
          <Button
            type="button"
            @click="$router.push('championship/edit/' + slotProps.data.id)"
            icon="pi pi-pencil"
            v-if="$checkRoles('championshipEdit')"
            class="p-button-success p-ml-2  p-button-rounded"  v-tooltip="'تعديل'"
          ></Button>
          <Button
            type="button"
            icon="pi pi-trash"
            v-if="$checkRoles('championshipDelete')"
            @click="deleteItem(slotProps.data.id)"
            class="p-button-warning  p-button-rounded"
            v-tooltip="'حذف'"
          ></Button>
        </template>
      </Column> -->
    </DataTable>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      selectedColumns: [],
      columns: [
        {
          field: 'name',
          header: 'الاسم',
        },
        {
          field: 'location',
          header: 'المكان',
        },
      ],
      loading: true,
      id: null,
      item: {},
      logo: null,
    };
  },
  methods: {
    arabicDay(value) {
      if (value) {
        const duration = moment(String(value));
        const list = [
          'السبت',
          'الاحد',
          'الاثنين',
          'الثلاثاء',
          'الاربعاء',
          'الخميس',
          'الجمعه',
        ];
        return list[duration.format('d')];
      }
    },
    arabicDate(value) {
      if (value) {
        const duration = moment(String(value));

        return duration.locale('ar').format('DD MMMM yyyy');
      }
    },
    printHtml() {
      const list = [];

      for (const itm of this.list) {
        list.push({
          yearName: itm.yearId.name,
          academieOneImage: this.$baseUploadURL + itm.academieOneId.image,
          academieOneUserName: itm.academieOneId.userName,
          academieTwoImage: this.$baseUploadURL + itm.academieTwoId.image,
          academieTwoUserName: itm.academieTwoId.userName,
          timeStart: this.$TimeFormatz(itm.timeStart),
          timeEnd: this.$TimeFormatz(itm.timeEnd),
          stadium: itm.stadium,
        });
      }

      const body = {
        list,
        item: {
          year: this.item.year,
          undier: this.item.undier,
          arabicDay: this.arabicDay(this.item.day),
          arabicDate: this.arabicDate(this.item.day),
        },
        logo: this.logo,
      };
      this.$http.post(`ascorchit/print`, body).then((res) => {
        window.open(this.$basePdfURL + 'championship/' + res.data, '_blank');
        this.$toast.add({
          severity: 'success',
          summary: 'تم بنجاح',
          detail: 'تم الطباعه بنجاح',
          life: 3000,
        });
      });
    },
    printHtmlPlayers() {
      const list = [];
      for (const itm of this.selectedItems) {
        list.push({
          year: itm.yearId,
          teamOneId: itm.teamOneId,
          teamTwoId: itm.teamTwoId,
          matcheNumber: itm.matcheNumber,
          // academieOneImage: this.$baseUploadURL + itm.academieOneId.image,
          academieOneUserName: itm.academieOneId.userName,
          academieOneId: itm.academieOneId.id,
          academieTwoId: itm.academieTwoId.id,
          // academieTwoImage: this.$baseUploadURL + itm.academieTwoId.image,
          academieTwoUserName: itm.academieTwoId.userName,
          stadium: itm.stadium,
          item: {
            year: this.item.year,
            undier: this.item.undier,
            arabicDay: this.arabicDay(this.item.day),
            arabicDate: this.arabicDate(this.item.day),
          },
          logo: this.logo,
        });
      }

      this.$http.post(`ascorchit/printHtmlPlayers`, list).then((res) => {
        window.open(this.$basePdfURL + 'championship/' + res.data, '_blank');
        this.$toast.add({
          severity: 'success',
          summary: 'تم بنجاح',
          detail: 'تم الطباعه بنجاح',
          life: 3000,
        });
      });
    },
    // updateStatus(id) {
    //   this.$http.get(`championship/updateStatus/${id}`).then(
    //     () => {
    //       this.$toast.add({
    //         severity: 'success',
    //         summary: 'تم بنجاح',
    //         detail: 'تم التعديل بنجاح',
    //         life: 3000,
    //       });
    //     },
    //     (err) => {
    //       this.loading = false;
    //       this.$toast.add({
    //         severity: 'error',
    //         summary: 'هناك خطأ',
    //         detail: err.response.data.message,
    //         life: 3000,
    //       });
    //     },
    //   );
    // },
    updateStatus(data) {
      this.$http
        .put(`championship/${data.id}`, {
          teamOneGoal: data.teamOneGoal,
          teamTwoGoal: data.teamTwoGoal,
        })
        .then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
          },
          (err) => {
            this.loading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
    },
    onCellEdit(newValue, props) {
      let body = {};
      body[props.column.field] = newValue;
      this.$http.put(`championship/${props.data.id}`, body).then(
        () => {
          this.$toast.add({
            severity: 'success',
            summary: 'تم بنجاح',
            detail: 'تم التعديل بنجاح',
            life: 3000,
          });
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
    getData() {
      const id = this.$route.params.id;
      this.$http.get(`championship?ascorchitId=${id}`).then(
        (response) => {
          this.loading = false;
          this.list = response.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col));
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    deleteAll() {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http
            .post(`championship/deleteList`, {
              idList: this.selectedItems,
            })
            .then(
              () => {
                this.getData();
                this.$toast.add({
                  severity: 'error',
                  summary: 'تم بنجاح',
                  detail: 'تم الحذف بنجاح',
                  life: 3000,
                });
              },
              (err) => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'هناك خطأ',
                  detail: err.response.data.message,
                  life: 3000,
                });
              },
            );
        },
      });
    },
    deleteItem(id) {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http.delete(`championship/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: 'error',
                summary: 'تم بنجاح',
                detail: 'تم الحذف بنجاح',
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
        },
      });
    },
    getLeague() {
      this.$http
        .post(`league/search`, {
          select: ['image'],
          where: {
            status: true,
          },
          order: {
            id: 'DESC',
          },
        })
        .then((res) => {
          const list = res.data;
          if (list.length > 0) {
            this.logo = this.$baseUploadURL + list[0].image;
          } else {
            this.logo = '/images/logo.png';
          }
        });
    },
  },
  created() {
    this.getData();
    this.selectedColumns = this.columns;

    this.id = this.$route.params.id;

    this.getLeague();
    this.$http.get(`ascorchit/${this.id}`).then((res) => {
      this.item = res.data;
    });
  },
};
</script>

<style></style>
